<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="lokkDetail">查看</el-button>
      <el-button class="itemBtn btnColor" @click="remarkReading"
        >标记为已读</el-button
      >
    </div>

    <!-- 表格  :class="{ tableBox1: fullscreen }" -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in tabelHeadeTitle">
          <el-table-column
            :key="index"
            :label="item.name"
            sortable
            :prop="item.field"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
            <!-- <template slot-scope="scope">
              <div v-if="item.custom">{{ scope.row.msgType | fitlerType }}</div>
              <div v-else>{{ scope.row[item.field] }}</div>
            </template> -->
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 消息详情 -->
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
      <div class="headebut">{{ messageDetail }}</div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";

import paging from "@/components/pagings.vue";
import screenfull from "screenfull";

export default {
  inject: ["reload"],
  components: {
    paging,
  },
  filters: {
    fitlerType(val) {
      if (val === "bao_jia_shi_xiao:msg_type") {
        return "客户报价失效";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      messageDetail: "",
      dialogFormVisible: false,
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "消息类型",
          field: "msgTypeShow",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: false,
          // custom: true, //自定义单元格内容
        },
        {
          name: "标题",
          field: "title",
          width: "120",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: false,
        },
        {
          name: "状态",
          field: "isReadShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: false,
        },
        {
          name: "发送时间",
          field: "createTime",
          width: "120",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: false,
        },
      ],
      tableData: [],
      selectTableData: [], //勾选数据
    };
  },
  updated() {},
  created() {},
  mounted() {
    const that = this;
    this.getData();
  },
  watch: {},
  methods: {
    // 查看详情
    async lokkDetail() {
      if (this.selectTableData.length > 1) {
        this.$message.warning("只可以勾选一条数据查看");
      } else if (this.selectTableData.length === 0) {
        this.$message.warning("请勾选一条数据");
      } else {
        await Api.getMsgDetail({ msgId: this.selectTableData[0].msgId }).then(
          (res) => {
            if (res.data.status == "success") {
              this.messageDetail = res.data.result.title;
            }
          }
        );
        this.dialogFormVisible = true;
      }
    },
    // 标记为已读
    remarkReading() {
      let idArry = [];
      // 处理消息id  msgId
      this.selectTableData.forEach((item) => {
        idArry.push(item.msgId);
      });

      let param = {};
      param.adminmsgId = idArry;
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.isReadApi(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success("修改成功");
          this.getData();
        }
      });
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
      // console.log(e);
    },
    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      let pagsHeigth = 40; //分页组件
      var otherHeight = btnListHeight + pagsHeigth + 60 + 10; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 获取数据
    getData() {
      let queryData = {};
      queryData.pageStart = this.pageNum;
      queryData.pageTotal = this.size;
      Api.adminmsgListApi(queryData).then((res) => {
        if (res.data.status == "success") {
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
    display: flex;
    .inputstyle {
      width: 100%;
      display: flex;
    }
  }
}

.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}

.frominput ::v-deep {
  .el-input__inner {
    height: 30px;
  }
}
.footbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-col {
  border: 0 !important;
}
.headebut {
  max-height: 450px;
  overflow-y: scroll;
}
</style>
